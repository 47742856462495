import permissions from '../../permissions'

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon'
  },
  {
    title: 'Map',
    route: 'map',
    icon: 'MapIcon'
  },
  {
    title: 'User requests',
    route: 'user_requests',
    fa_icon: 'exclamation'
  },
  {
    title: 'Outlets',
    fa_icon: 'store',
    children: [
      {
        title: 'Outlets',
        route: 'outlets'
      },
      {
        title: 'Segments ',
        route: 'outlet_segments'
      }
    ]
  },
  {
    title: 'Products',
    icon: 'ShoppingBagIcon',
    route: 'products',
    children: [
      {
        title: 'Products',
        route: 'products'
      },
      {
        title: 'Warehouse products',
        route: 'warehouse_products'
      },
      {
        title: 'Categories',
        route: 'product_categories'
      }
    ]
  },
  {
    title: 'Promotional Items',
    icon: 'AwardIcon',
    children: [
      {
        title: 'Items',
        route: 'promotional_items'
      },
      {
        title: 'Types',
        route: 'promotional_item_types'
      }
    ]
  },
  {
    title: 'Warehouses',
    route: 'warehouses',
    fa_icon: 'warehouse',
    requiredPermission:  permissions.ViewWarehouses
  },
  {
    title: 'Sales',
    route: 'sales',
    icon: 'ShoppingCartIcon'
  },
  {
    title: 'Distribution vehicles',
    route: 'distribution_vehicles',
    icon: 'TruckIcon',

    // it needs either ViewDistributionVehicles or has_vehicle permission
    requiredPermission:  permissions.ViewDistributionVehicles,
    needsVehiclePermission: true
  },
  {
    title: 'Geo Areas',
    fa_icon: 'draw-polygon',
    children: [
      {
        title: 'Areas',
        route: 'areas',
        requiredPermission:  permissions.ViewAllAreas
      },
      {
        title: 'Territories',
        route: 'territories'
      },
      {
        title: 'Districts',
        route: 'districts'
      }
    ]
  },
  {
    title: 'Surveys',
    route: 'surveys',
    fa_icon: 'poll',
    requiredPermission:  permissions.ViewWarehouses
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Users',
        route: 'users',
        requiredPermission:  permissions.ManageUsers
      },
      {
        title: 'Roles',
        route: 'roles',
        requiredPermission:  permissions.ManageRoles
      }
    ]
  },
  {
    title: 'Config',
    icon: 'SettingsIcon',
    requiredPermission:  permissions.ManageSystemConfig,
    children: [
      {
        title: 'Default map view',
        route: 'config/default_map_view'
      },
      {
        title: 'General config',
        route: 'config/general_config'
      }
    ]
  }
    
]
