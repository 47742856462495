<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>


        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{ (userData.name + ' ' +  userData.last_name) || userData.email }}
                        </p>
                        <span class="user-status">{{userData.username}}</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        badge
                        :src="require('@/assets/images/avatars/13-small.png')"
                        class="badge-minimal"
                        badge-variant="success"
                    />
                </template>

                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon
                        size="16"
                        icon="LogOutIcon"
                        class="mr-50"
                    />
                    <span>Logout</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
    import {
        BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar
    } from 'bootstrap-vue'

    export default {
        components: {
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BAvatar

            // Navbar Components
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                userData: this.$store.state.app.userData
            }
        },
        methods: {
            logout() {
                // Remove userData from localStorage
                // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                //localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                //localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

                // Remove userData from localStorage
                localStorage.removeItem('userData')

                const thisIns = this
                thisIns.$store.dispatch('app/setLoginStatus', false)
                thisIns.$store.dispatch('app/updateUserData', {})
                this.$http.get('/api/management/v1/logout/')
                    .then(function() {
                        thisIns.$router.push('/login')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    })
            }
        }
    }
</script>
